import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export class AgreementModal extends React.Component {

    constructor(props) {
        super(props);

        this.handleAgree = this.handleAgree.bind(this);
    }

    async handleAgree() {
        await this.props.onAgree();
    };

    render() {
        return (
            <Modal fade={false} isOpen={this.props.open} className="modal-full">
                <ModalHeader>
                    <div dangerouslySetInnerHTML={{ __html: decodeURIComponent(this.props.title) }} />
                </ModalHeader>
                <ModalBody>
                    <div dangerouslySetInnerHTML={{ __html: decodeURIComponent(this.props.text) }} />
                </ModalBody>
                <ModalFooter className="text-center">
                    <Button onClick={this.handleAgree} variant="contained" color="primary" className="mr-auto"> Entrar</Button>
                </ModalFooter>
            </Modal>
        )
    }
}