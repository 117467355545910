import axios from 'axios';
import uuidv4 from '.././functions/uuidv4';

export default class AddonsService {

    async getSegmented(consentId) {
        try {
            const response = await axios({
                method: 'get',
                url: '/api/panel/segmented_addons',
                headers: {
                    "Accept": "application/json",
                    "X-Nonce": uuidv4(),
                    "X-Consent": consentId,
                    "Pragma": 'no-cache'
                }
            });

            return response.data.AddOns;

        } catch (error) {
            console.log("error", error);
        }
    }

    async getAcquired(consentId) {
        try {
            const response = await axios({
                method: 'get',
                url: '/api/panel/acquired_addons',
                headers: {
                    "Accept": "application/json",
                    "X-Nonce": uuidv4(),
                    "X-Consent": consentId,
                    "Pragma": 'no-cache'
                }
            });
            return response.data.AddOns;
        } catch (error) {
            console.log("error", error);
        }
    }

    async getAll(consentId) {
        try {
            const response = await axios({
                method: 'get',
                url: '/api/panel/potential_addons',
                headers: {
                    "Accept": "application/json",
                    "X-Nonce": uuidv4(),
                    "X-Consent": consentId,
                    "Pragma": 'no-cache'
                }
            });
            return response.data.AddOns;
        } catch (error) {
            console.log("error", error);
        }
    }

    async getRecomendationSegmented(consentId, recommendationId) {
        try {
            const response = await axios({
                method: 'get',
                url: `/api/panel/recommendations/${recommendationId}/segmented_addons`,
                headers: {
                    "Accept": "application/json",
                    "X-Nonce": uuidv4(),
                    "X-Consent": consentId,
                    "Pragma": 'no-cache'
                }
            });
            return response.data.AddOns;
        } catch (error) {
            console.log("error", error);
        }
    }

    async getDefault() {
        try {
            const response = await axios({
                method: 'get',
                url: '/api/panel/default_addons',
                headers: {
                    "Accpt": "application/json",
                    "X-Nonce": uuidv4(),
                    "Pragma": 'no-cache'
                }
            });
            return response.data.AddOns;
        } catch (error) {
            console.log("error", error);
        }
    }
}