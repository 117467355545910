import axios from 'axios';
import uuidv4 from '.././functions/uuidv4';

export default class AgreementsService {

    async getLast() {
        try {
            const response = await axios({
                method: 'get',
                url: '/api/panel/last_agreement',
                headers: {
                    "Accept": "application/json",
                    "X-Nonce": uuidv4(),
                    "Pragma": 'no-cache'
                }
            });
            return response.data;
        } catch (error) {
            console.log("error", error);
        }
    }
}