import axios from 'axios';
import uuidv4 from '.././functions/uuidv4';

export default class InstallationsService {

    async getInstallation(consentId) {
        try {
            const response = await axios({
                method: 'get',
                url: '/api/panel/installations',
                headers: {
                    "Accept": "application/json",
                    "X-Nonce": uuidv4(),
                    "X-Consent": consentId,
                    "Pragma": 'no-cache'
                }
            });
            return response.data;
        } catch (error) {
            console.log("error", error);
        }
    }

    async acceptAgreement(consentId) {
        try {
            const response = await axios({
                method: 'post',
                url: `api/panel/accept_agreement`,
                headers: {
                    "Accept": "application/json",
                    "X-Nonce": uuidv4(),
                    "X-Consent": consentId,
                    "Pragma": 'no-cache'
                }
            });
            return response.status === 204;
        } catch (error) {
            console.log("error", error);
        }
    }
}