import React, { Component, Fragment } from 'react';
import { Card } from './Card';
import { CardSkeleton } from './CardSkeleton';
import { Row } from 'reactstrap';
import { AgreementModal } from './AgreementModal';
import queryString from 'query-string';
import InstallationsService from '.././services/installationsService';
import AgreementsService from '.././services/agreementsService';
import AddonsService from '.././services/addonsService';

export class Recommendation extends Component {
    constructor(context) {
        super();

        this.installationsService = new InstallationsService();
        this.agreementsService = new AgreementsService();
        this.addonsService = new AddonsService();

        this.state = {
            addons: [],
            consentId: "",
            recommendationId: "",
            open: false,
            agreementTile: "",
            agreementText: "",
            installationId: "",
            loading: true,
            isRecommendation: true,
            urlParameters: ""
        };
        this.setData = this.setData.bind(this);
        this.setAddonsData = this.setAddonsData.bind(this);
        this.setAgreementData = this.setAgreementData.bind(this);
        this.handleAgree = this.handleAgree.bind(true);
        this.onAgree = this.onAgree.bind(this);
        this.setUrlParameters = this.setUrlParameters.bind(this);
    }

    componentDidMount() {
        let parsed = queryString.parse(this.props.location.search.toLowerCase());
        let consentId = parsed.consentid;
        let recommendationId = parsed.recommendationid;
        this.setState({ consentId: consentId, recommendationId: recommendationId });

        if (consentId && recommendationId) {
            this.fetchData(consentId);
        }
    }

    async fetchData(consentId) {
        let installation = await this.installationsService.getInstallation(consentId);
        this.setData(installation);
    }

    async setData(data) {
        if (data) {
            this.setUrlParameters(data);

            if (data.AgreementSigned) {
                this.fetchAddonsData(this.state.consentId, this.state.recommendationId);
            } else {

                this.setState({ installationId: data.InstallationId });

                var agreement = await this.agreementsService.getLast(this.state.consentId);
                this.setAgreementData(agreement);
            }
        }
        else {
            this.setState({ loading: false });
        }
    }

    setUrlParameters(data) {
        var parameters = 'cod=';
        var customerCode = 'NoCustomer';
        var partnerCode = 'NoPartner';
        var installationId = 'NoInstallation';

        if (data.InstallationId !== undefined) {
            installationId = data.InstallationId;
        }

        if (data.InfoRequired) {
            var infoRequired = data.InfoRequired;

            if (infoRequired.SegmentationData) {
                var segmentationData = infoRequired.SegmentationData;

                if (segmentationData.CustomerCode !== undefined) {
                    customerCode = segmentationData.CustomerCode;
                }

                if (segmentationData.PartnerCode !== undefined) {
                    partnerCode = segmentationData.PartnerCode;
                }
            }
        }

        parameters += customerCode + "_" + partnerCode + "_" + installationId;

        this.setState({ urlParameters: parameters });
    }

    setAgreementData(data) {
        if (data) {
            this.setState({ agreementTile: data.Title, agreementText: data.Text, open: true });
        }
    }

    async fetchAddonsData(consentId, recommendationId) {
        if (consentId && recommendationId) {
            var addons = await this.addonsService.getRecomendationSegmented(this.state.consentId, recommendationId);
            this.setAddonsData(addons);
        }
    }

    setAddonsData(addons) {
        if (addons) {
            this.setState({ addons: addons, loading: false });
        }
    }

    handleAgree = async () => {
        var accepted = await this.installationsService.acceptAgreement(this.state.consentId);
        if (accepted) {
            this.onAgree();
        }
    }

    onAgree = () => {
        this.fetchAddonsData(this.state.consentId, this.state.recommendationId);
        this.setState({ open: false });
    }

    render() {
        return (
            <Fragment>
                <AgreementModal
                    title={this.state.agreementTile}
                    text={this.state.agreementText}
                    open={this.state.open}
                    onAgree={() => this.handleAgree()}>
                </AgreementModal>
                {this.state.consentId && (
                    <Fragment>
                        <Row>
                            <div className="card-columns">
                                {this.state.loading && (
                                    <Fragment>
                                        <CardSkeleton />
                                        <CardSkeleton />
                                        <CardSkeleton />
                                    </Fragment>
                                )}
                                {this.state.addons.map((addon, i) => {
                                    return (<Card
                                        isPromoted={addon.IsPromoted}
                                        imagePath={addon.ImagePath}
                                        name={addon.Name}
                                        isNew={addon.IsNew}
                                        category={addon.Category}
                                        description={addon.Description}
                                        codActive={addon.CodActive}
                                        isRecommendation={this.state.isRecommendation}
                                        url={addon.LandingUrl}
                                        urlParameters={this.state.urlParameters}
                                    >
                                    </Card>);
                                })}
                            </div>
                        </Row>
                    </Fragment>
                )}
            </Fragment>
        );
    }
}
