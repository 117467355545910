import React, { Component } from 'react';
import loadingImage from '../images/768x601_Loading.jpg';

export class CardSkeleton extends Component {
    render() {
        return (<div className="card">
            <div className="embed-responsive embed-responsive-4by3 card-image__skeleton">
                <img src={loadingImage } className="card-img-top embed-responsive-item" alt="..."></img>
            </div>
            <div className="card-body">
                <h5 className="card-title">
                    <span className="card__skeleton" style={{ paddingBottom: '1.25rem' }}></span>
                </h5>
                <h6 className="card-subtitle mb-2 text-muted"><span className="card__skeleton" style={{ paddingBottom: '1rem' }}></span></h6>
                <p className="card-text"><span className="card__skeleton"></span></p>
                <p className="card-text"><span className="card__skeleton"></span></p>
                <p className="card-text"><span className="card__skeleton"></span></p>
            </div>
            <div className="card-footer">
                <a className="btn btn-primary disabled" href="/" role="button">Más información</a>
            </div>

        </div>);
    }
}